import Container from '@/components/Container/Container';
import Navbar from '@/components/Navbar/Navbar';
import { Typography } from '@/shared';
import { fetchStory } from '@/shared/helpers/slugHelper';
import { GlobalStyles, theme } from '@/shared/providers/GlobalStyles';
import { ColorsObject } from '@/shared/types/layoutTypes';
import { ConfigStoryblok, NavbarStoryblok, NotfoundStoryblok } from '@/shared/types/storyblok/storyblok-types';
import { Icon } from '@/shared/ui/Icon/Icon';
import { ISbStoriesParams, ISbStoryData, useStoryblokState } from '@storyblok/react';
import { GetStaticProps } from 'next';
import Link from 'next/link';
import { ThemeProvider } from 'styled-components';
import styles from './404.module.scss';

type PageProps = {
    story: ISbStoryData<NotfoundStoryblok>;
    config: ConfigStoryblok;
    colors: ColorsObject;
};

export default function Custom404({ story: initialStory, config, colors }: PageProps) {
    const story = useStoryblokState(initialStory) as any;
    if (!initialStory && !config && !colors) return;
    const navbarData = config && config.content.components.find((comp: NavbarStoryblok) => comp.component === 'navbar');
    const { content } = story;
    return (
        <ThemeProvider theme={theme}>
            <GlobalStyles colors={colors} />
            <Container code="fullpage">
                <>
                    <Navbar blok={navbarData} pageType={'page'} />
                    <main className={styles.main}>
                        <Container code="standard">
                            <Typography.H4 className={styles.pretitle}>{content.pretitle}</Typography.H4>
                            <div className={styles.titleWrapper}>
                                <Typography.H1 className={styles.title}>{content.title}</Typography.H1>
                            </div>
                            <Typography.P darkTheme className={styles.description}>
                                {content.description}
                            </Typography.P>
                            <div className={styles.button}>
                                <Link href={content.button?.story.full_slug.replace(/home/g, '')}>
                                    <Typography.Compressed className={styles.buttonText}>
                                        {content.button!.title}
                                    </Typography.Compressed>
                                </Link>
                                <Icon style={{ color: 'var(--core80)', marginLeft: '16px' }} name="arrow_forward" />
                            </div>
                        </Container>
                    </main>
                </>
            </Container>
        </ThemeProvider>
    );
}

export const getStaticProps: GetStaticProps = async context => {
    const locale = context?.locale;
    const production = process.env.NEXT_PUBLIC_ENVIRONMENT === 'production';
    const version: ISbStoriesParams = {
        version: production ? 'published' : 'draft',
        resolve_links: 'url',
        language: locale,
    };
    const { data, config, colors } = await fetchStory('notfound', version);

    return {
        props: {
            story: data?.story || false,
            config: config?.story || false,
            colors: colors || false,
        },
    };
};
